body{
    background-color: rgb(255, 255, 255) ;
}
.box{
    border: 1px solid rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
.map{
    position: relative;
    top:20%;
    left:0%;
    z-index: 1;
}

.logo{
    position: absolute;
    top:7%;
    left:60%;
    z-index: 2;
    animation-name: popper;
    animation-duration: 1s;
    animation-delay: 0.01s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
.circle{
    position: absolute;
    top:36%;
    left:59%;
    z-index: 1;
    width:20px;
    height:10px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(18, 71, 43);
    filter: blur(1px);
    border-radius: 50%;
    animation-name: shadow;
    animation-duration: 1s;
    animation-delay: 0.01s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes popper{
    0% {
        transform : translate3d(-40px,-3px,0);
        opacity : 0.5
    }
    25%{
        transform : translate3d(-10px,0,0) scale3d(1.1,1.1,1);
        opacity : 0.6
    }
   
    50%{
        transform : translate3d(0,0,0) scale3d(1.5,1.5,1.2);
        opacity : 0.9
    }
    75%{
        transform : translate3d(0,-17px,0) scale3d(1.5,1.5,1.2);
        opacity : 1
    }

    100%{
        transform : translate3d(0,0,0);
        opacity : 0.5
    }
}
@keyframes shadow{
    0%{
        transform: translate3d(-40px,-3px,0) scale3d(0.9,0.9,0.9);
        opacity: 0.6;
    }
    25%{
        transform : translate3d(-10px,0,0) scale3d(0.8,0.8,0.8);
       opacity:0.8;
    }
    50%{
        transform: translate3d(0,10px,0) scale3d(0.5,0.5,0.5);
    }
    75%{
        transform : translate3d(0,0,0) scale3d(0.5,0.5,0.2);
       
    }
    100%{
        transform: scale3d(1,1,1);
        
    }
}